export const colors = {
  PRIMARY_COLOR: "blue-500",
  SECONDARY_COLOR: "blue-100",
};

export const colorsByTier = {
  basic: "yellow",
  rare: "green",
  special: "sky",
  "s.rare": "purple",
  elite: "pink",
  legend: "orange",
  myth: "indigo",
};

export const colorHashes = {
  PRIMARY_COLOR: "#3B82F6",
  SECONDARY_COLOR: "#DBEAFE",
  BUG: "#059669",
  DARK: "#000000",
  DRAGON: "#6D28D9",
  ELECTRIC: "#F59E0B",
  FAIRY: "#DB2777",
  FIGHTING: "#F87171",
  FIRE: "#DC2626",
  FLYING: "#9CA3AF",
  GHOST: "#7C3AED",
  GRASS: "#10B981",
  GROUND: "#78350F",
  ICE: "#93C5FD",
  NORMAL: "#6B7280",
  PSYCHIC: "#F472B6",
  ROCK: "#065F46",
  STEEL: "#0D9488",
  WATER: "#60A5FA",
};

export const HEADER_HEIGHT = 64;

export const MON_CARD_WIDTH = "w-1/3 sm:w-1/4 lg:w-1/6 xl:w-1/8";

export const EMPTY_SQUARE_IMAGE_DATA =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6CAQAAAAi5ZK2AAABjklEQVR42u3RAQ0AAAzCsOPf9G2Q0ElYc5orFkAXdEEXdEEXdEEXdEEXdEEXdEEXdEEXdEGHLuiCLuiCLuiCLuiCLuiCLuiCLuiCLuiCDl3QBV3QBV3QBV3QBV3QBV3QBV3QBV3QBR26oAu6oAu6oAu6oAu6oAu6oAu6oAu6oAs6dEEXdEEXdEEXdEEXdEEXdEEXdEEXdEEXdOgWQBd0QRd0QRd0QRd0QRd0QRd0QRd0QRd0QYcu6IIu6IIu6IIu6IIu6IIu6IIu6IIu6IIOXdAFXdAFXdAFXdAFXdAFXdAFXdAFXdAFHbqgC7qgC7qgC7qgC7qgC7qgC7qgC7qgCzp0QRd0QRd0QRd0QRd0QRd0QRd0QRd0QRd06BZAF3RBF3RBF3RBF3RBF3RBF3RBF3RBF3RBhy7ogi7ogi7ogi7ogi7ogi7ogi7ogi7ogg5d0AVd0AVd0AVd0AVd0AVd0AVd0AVd0AUduqALuqALuqALuqALuqALuqALuqALuqALOnRBF3RBF3RBF3RBV0cP0TwA+xgYeJcAAAAASUVORK5CYII=";
